import React, { useState, useEffect } from "react"
import _ from "underscore"
import { PDFDownloadLink } from "@react-pdf/renderer"
import { Modal, Button } from "antd"
import ProgramMatrixFilters from "./Filters"
import commonStrings from "../../commonStrings"
import ActivityDetailsPDF from "./ActivityDetailsPDF"
import pdfSvg from "../../../assets/images/pdf-file.svg"
import { formatTimestamp } from "../../utilities"
import dottedLoader from "../../../assets/images/dottedloader.gif"

function ActivityDetails({ matrix, currentSchool }) {
  const [filters, setFilters] = useState({
    categories: [],
    tiers_of_support: [],
    standards: [],
    dessa_competencies: [],
    internal_selected_grades: [],
  })
  const [filteredItems, setFilteredItems] = useState([])
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isGeneratingPDF, setIsGeneratingPDF] = useState(false)
  const [selectedDessaFilters, setselectedDessaFilters] = useState("")
  const [categoriesData, setCategoriesData] = useState([])
  const standards = (key) => {
    const hash = {}
    _.pluck(window.gon.SEL_STANDARDS, "standards").forEach((array) => {
      _.extend(hash, array)
    })
    return hash[key]
  }

  const itemStandard = (key, type, standard) => (
    <li key={key}>
      <div className="main-item-standards">
        <div className="inner-item-standards">
          <span>
            {key}:{" "}
            {type === "student"
              ? standards(key).student_indicator_language
              : standards(key).adult_indicator_language}
          </span>
        </div>
        <div className="inner-list-of-item-standards">
          <li>{standard.connection_explanation}</li>
        </div>
      </div>
    </li>
  )

  const startCase = (str) => str.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(),
  )

  const findLoadIndicatorsByKeys = (keys) => {
    const categories = []
    const colors = ["#A6192E", "#33485D", "#E7A922", "#0CA4A5"]

    keys.forEach((key) => {
      Object.keys(matrix.load_indicators).forEach((category) => {
        if (
          matrix.load_indicators[category].includes(key)
          && !categories.some((cat) => cat.name === category)
        ) {
          const colorIndex = categories.length % colors.length
          const color = colors[colorIndex]
          categories.push({ name: category, color })
        }
      })
    })
    return categories
  }

  const renderItem = (item, index) => {
    if (_.isNull(item.name)) return null

    const selStandardsKeys = Object.keys(item.sel_standards)
    const categories = findLoadIndicatorsByKeys(
      Object.keys(item.sel_standards),
    )
    const backgroundColorClass = index % 2 === 0 ? "item-even" : "item-odd"
    const backgroundColorClassforGrades = index % 2 === 0 ? "item-odd" : "item-even"
    return (
      <div>
        <div
          key={item.id}
          className={`report-container ${backgroundColorClass}`}
        >
          <div className="report-header">
            <div className="activity-name">
              <span>{startCase(item.activity)}</span>
            </div>
            <div className="activity-description-parent-div">
              <div className="activity-description">
                <span>{item.description}</span>
              </div>
              <div className="report-categories">
                {categories.map((category, index) => (
                  <span key={category.name} style={{ color: category.color }}>
                    {category.name}
                    {index < categories.length - 1 && (
                      <span style={{ marginLeft: "3px", marginRight: "3px" }}>
                        |
                      </span>
                    )}
                    {(index + 1) % 3 === 0 && index < categories.length - 1 && (
                      <br />
                    )}
                  </span>
                ))}
              </div>
            </div>
          </div>
          {item.category !== "Adult SEL"
            && item.internal_selected_grades
            && Array.isArray(item.internal_selected_grades)
            && item.internal_selected_grades.length > 0 && (
              <div className="main-grade-level">
                <div className="inner-grade-level">
                  <div className="grade-level-title">
                    {commonStrings.gradeLevelTitle}
                  </div>
                  <div className="grades">
                    <div className="individual-grades">
                      {item.internal_selected_grades.map(
                        (grade, index) => grade
                          && grade.grade && (
                            <span
                              key={index}
                              className={`grade-item ${backgroundColorClassforGrades}`}
                            >
                              {grade.grade}
                            </span>
                        ),
                      )}
                    </div>
                  </div>
                </div>
              </div>
          )}

          <div className="report-details">
            <div className="tier-domain-time">
              <div className="report-section">
                <div className="tier-type-title">
                  <span>{commonStrings.TierType}</span>
                </div>
                <div className="tier-of-support-type">
                  <span>{item.tier_of_support}</span>
                </div>
              </div>
              <div className="report-section">
                <div className="domain-title">
                  <span>{commonStrings.Domain}</span>
                </div>
                <div className="tier-of-support-type">
                  <span>{item.category}</span>
                </div>
              </div>
              <div className="report-section">
                <div className="length-of-time">
                  <span>{commonStrings.LengthofTime}</span>
                </div>
                <div className="tier-of-support-type">
                  <span>{item.length_of_time}</span>
                </div>
              </div>
            </div>
            <div className="report-section-data-collected">
              <div className="data-collected">
                <span>{commonStrings.DataCollected}</span>
              </div>
              <div className="tier-of-support-type">
                <span
                  style={{
                    color: item.data_collection ? "#000000" : "#B9B9B9",
                  }}
                >
                  {item.data_collection || "Data not yet collected."}
                </span>
              </div>
            </div>
          </div>
          <div className="activity-details-report-content">
            <div className="promotes-sel-body">
              <div className="report-promotes-sel">
                <span>How {item.activity} Promotes SEL</span>
              </div>
              <div className="sel-standards-body">
                <ul>
                  {selStandardsKeys
                    .filter(
                      (key) => item.sel_standards[key].connection_type === "promotes",
                    )
                    .map((key) => itemStandard(
                      key,
                      item.adult ? "adult" : "student",
                      item.sel_standards[key],
                    ))}
                </ul>
              </div>
            </div>
            <div className="report-reinforces">
              <div className="report-sel-reinforces">
                <span>How {item.activity} Reinforces SEL</span>
              </div>
              <div className="reinforces-list">
                <ul>
                  {selStandardsKeys
                    .filter(
                      (key) => item.sel_standards[key].connection_type === "reinforces",
                    )
                    .map((key) => itemStandard(
                      key,
                      item.adult ? "adult" : "student",
                      item.sel_standards[key],
                    ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const indicators = () => _.sortBy(
    _.uniq(
      _.flatten(
        matrix.completed_items.map((item) => Object.keys(item.sel_standards).map((key) => ({
          label: `${key}: ${
            item.adult
              ? standards(key).adult_indicator_language
              : standards(key).student_indicator_language
          }`,
          value: [key, item.adult ? "adult" : "student"].join("-"),
        }))),
      ),
      false,
      (i) => i.value,
    ),
    (i) => i.value,
  )

  const applyFilters = () => {
    let items = matrix.completed_items.filter((i) => i.activity)
    if (filters.categories.length) {
      items = _.filter(items, (item) => _.contains(filters.categories, item.category))
    }
    if (filters.tiers_of_support.length) {
      items = _.filter(items, (item) => _.contains(filters.tiers_of_support, item.tier_of_support))
    }
    if (filters.internal_selected_grades.length) {
      items = _.filter(
        items,
        (item) => item.internal_selected_grades
          && item.internal_selected_grades.some((level) => filters.internal_selected_grades.includes(level.grade)),
      )
    }

    if (filters.standards.length) {
      items = _.filter(
        items,
        (item) => _.intersection(
          filters.standards,
          _.keys(item.sel_standards).map((k) => [k, item.adult ? "adult" : "student"].join("-")),
        ).length,
      )
    }

    if (filters.standards.length) {
      items = _.filter(
        items,
        (item) => _.intersection(
          filters.standards,
          _.keys(item.sel_standards).map((k) => [k, item.adult ? "adult" : "student"].join("-")),
        ).length,
      )
    }

    if (filters.dessa_competencies.length) {
      const dessaCompetencies = _.uniq(_.flatten(filters.dessa_competencies))

      items = _.filter(
        items,
        (item) => _.intersection(dessaCompetencies, _.keys(item.sel_standards)).length,
      )
    }
    setFilteredItems(items)
  }

  useEffect(() => {
    applyFilters()
  }, [filters])

  const showModal = () => {
    setIsModalVisible(true)
    setIsGeneratingPDF(true)

    setTimeout(() => {
      setIsGeneratingPDF(false)
    }, 3000)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
    setIsGeneratingPDF(false)
  }

  useEffect(() => {
    const computeCategoriesData = () => {
      const data = filteredItems.map((item) => {
        if (_.isNull(item.name)) return []
        return findLoadIndicatorsByKeys(Object.keys(item.sel_standards))
      })
      return data
    }

    setCategoriesData(computeCategoriesData())
  }, [filteredItems])

  const dateForReport = currentSchool && currentSchool.updated_at
    ? formatTimestamp(currentSchool.updated_at)
    : null
  const currentschoolLogo = currentSchool && currentSchool.logo && currentSchool.logo.url
    ? currentSchool.logo.url
    : null

  const getSelectedFilterValues = () => {
    const filterNamesMap = {
      categories: "Domains",
      tiers_of_support: "Tiers of Support",
      internal_selected_grades: "Grade Levels",
      standards: "Indicators",
      dessa_competencies: "Dessa Competencies",
    }

    const selectedFilters = Object.keys(filterNamesMap).reduce((acc, key) => {
      if (filters[key] && filters[key].length) {
        acc.push({
          name: filterNamesMap[key],
          values: filters[key],
        })
      }

      return acc
    }, [])

    return selectedFilters.length > 0
      ? selectedFilters
      : [{ name: "No filters selected", values: [] }]
  }

  const filtersApplied = Object.values(filters).some(
    (filterArray) => filterArray.length > 0,
  )
  const onDessaFilterChange = (selectedDessaFilter) => {
    setselectedDessaFilters(selectedDessaFilter || [])
  }
  return (
    <div>
      <div className="main-program-matrix">
        <div className="pdf-filters-container">
          <div className="pdf-activity-details">
            <button onClick={showModal} className="open-modal-button">
              <img src={pdfSvg} alt="Open modal" className="pdf-icon-button" />
            </button>

            <Modal
              title={null}
              visible={isModalVisible}
              onCancel={handleCancel}
              footer={null}
              centered
              bodyStyle={{ textAlign: "center", padding: "30px" }}
              className="activity-details-modal"
            >
              {isGeneratingPDF ? (
                <div className="pdf-generation-container">
                  <div className="pdf-loader-section">
                    <img
                      src={dottedLoader}
                      alt="Loading..."
                      className="pdf-loader-icon"
                    />
                    <span className="pdf-preparing-text">
                      {commonStrings.pdfPreparing}
                    </span>
                    <span className="pdf-waiting-text">
                      {commonStrings.waiting}
                    </span>
                    <div className="pdf-action-buttons">
                      <Button onClick={handleCancel} className="cancel-button">
                        {commonStrings.cancel}
                      </Button>
                      <Button disabled className="download-button disabled">
                        {commonStrings.download}
                      </Button>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="pdf-ready-container">
                  <div className="pdf-info-section">
                    <img src={pdfSvg} alt="PDF Icon" className="pdf-icon" />
                    <span className="pdf-ready-text">
                      {filtersApplied
                        ? commonStrings.pdfReady
                        : "PDF is ready to download"}
                    </span>

                    {filtersApplied && (
                      <div className="selected-filters-list">
                        {getSelectedFilterValues().map((filter, index) => (
                          <div key={index} className="filter-item">
                            <span className="filter-name">{filter.name}:</span>{" "}
                            <span className="filter-values">
                              {filter.name === "Dessa Competencies"
                                ? selectedDessaFilters.length
                                  ? selectedDessaFilters.join(", ")
                                  : "No competencies selected"
                                : filter.values.join(", ")}{" "}
                            </span>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  <div className="pdf-action-buttons">
                    <Button onClick={handleCancel} className="cancel-button">
                      {commonStrings.cancel}
                    </Button>
                    <PDFDownloadLink
                      document={(
                        <ActivityDetailsPDF
                          categories={categoriesData}
                          items={filteredItems}
                          currentschoolLogo={currentschoolLogo}
                          filtersApplied={filtersApplied}
                          filters={filters}
                          selectedDessaFilters={selectedDessaFilters}
                        />
                      )}
                      fileName={`${
                        currentSchool.name || "school_name"
                      }_report(${dateForReport}).pdf`}
                    >
                      {({ loading }) => (
                        <Button className="download-button">
                          {loading ? "Loading..." : "Download"}
                        </Button>
                      )}
                    </PDFDownloadLink>
                  </div>
                </div>
              )}
            </Modal>
          </div>
          <div className="filters-container">
            <ProgramMatrixFilters
              items={matrix.completed_items}
              filters={filters}
              onChange={setFilters}
              setFilters={setFilters}
              indicators={indicators()}
              isDessaReport
              onDessaFilterChange={onDessaFilterChange}
            />
          </div>
        </div>
        {filteredItems.length > 0 ? (
          filteredItems.map((item, index) => renderItem(item, index))
        ) : (
          <div className="no-activity-message">
            {commonStrings.NoActivityExists}
          </div>
        )}
      </div>
    </div>
  )
}
export default ActivityDetails
