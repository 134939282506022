export const fixedGradeLabels = [
  "3K",
  "PreK",
  "K",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
]
export const elementIds = [
  "pdfContent1",
  "pdfContent2",
  "pdfContent3",
  "pdfContent4",
  "pdfContent5",
  "pdfContent6",
  "pdfContent7",
  "pdfContent8",
  "pdfContent9",
]
export const graphNamesArray = [
  "Universal support indicators",
  "Ratio between indicators that promote and reinforce SEL",
  "Activities with data.",
  "Ratio of universal, targeted, and tertiary activities.",
  "Activities Count By Matrix Competency.",
  "Activities Count By DESSA Competency.",
  "Number Of Students Supported By SEL Indicator.",
  "Number Of Students Supported By Matrix Competency.",
  "Number Of Students Supported By DESSA Competency",
  "Capacity vs. Actual for Targeted and Tertiary Activities.",
]
